import { createI18n } from 'vue-i18n'
import { GetQueryValue } from "@/utils/index"

const getCurrentLanguage = () => {
    let defaultLang = GetQueryValue('Accept-Language') 
    // let defaultLang = navigator.language
    if(defaultLang){
        defaultLang = defaultLang.indexOf('zh') !== -1 ? 'zh-CN' : 'en-US' // en-US/zh-CN
    }else{
        let language = localStorage.getItem('Accept-Language')
        if(language){
            defaultLang = language
        }else{
            defaultLang = 'zh-CN'
        }
    }
    localStorage.setItem('Accept-Language', defaultLang)
    return defaultLang
}

export default createI18n({
    legacy: false, // 让 setup 函数可以通过 t 访问
    globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
    locale: getCurrentLanguage() || 'zh-CN' , // 默认 zh-CN
    fallbackLocale: 'zh-CN',  //en-US 没有 就用 zh-CN
    messages: {
        'zh-CN': {
            bind: {
                envTitle:'礼品卡兑换',
                rightTitle:'绑卡记录',
                placeholder1:'请输入卡号',
                placeholder2:'请输入密码',
                submit:'确定',
                tipsTitle:'礼品卡说明：',
                tipsDetail1:'1、用户通过卡号和密码，可与自己账号作绑定，绑定账户后不支持解绑、退款，绑定成功后，可在【钱包】-【礼品卡】中查看金额。',
                tipsDetail2:'2、本卡对应的金额仅限在已开通礼帽出行服务的城市下单使用，不支持与优惠券共同使用，用户支付订单时，可选择【余额】支付，线上支付订单开发票时将不包含礼品卡支付的金额。',
                tipsDetail3:'3、线上订单发生退款时候，通过本卡支付的金额将退回消费账户中，不可提现。',
                tipsDetail4:'4、本卡不记名、不挂失，不可提现/兑换现金。',
                tipsDetail5:'5、如有礼品卡购买需求，请咨询客服热线4000090909。',
                toastContent1:'您还未登录，请登录后进行兑换~',
                toastContent2:'绑卡成功'
            },
            record:{
                envTitle:'绑卡记录',
                cardNum:'卡号：',
                yuan1:'',
                yuan2:'元',
                finishedText:'没有更多了~',
                loadingText:'加载中...',
                nodataTxt:'暂无数据',
            }
        },
        'en-US': {
            bind: {
                envTitle:'Redeem gift cards',
                rightTitle:'History cards',
                placeholder1:'Please enter card number',
                placeholder2:'Please enter password',
                submit:'Confirm',
                tipsTitle:'Tips for using gift cards:',
                tipsDetail1:'1. You can add a gift card to your account by entering card number and password. Once you added the card, it cannot be removed and refund. To view the amount, you can tap ‘wallet’ and choose ‘gift cards’.',
                tipsDetail2:'2. This card is only applicable in cities that Limao already launched. and cannot be combined with vouchers. The invoice will not cover the amount in this card as you pay with balance.',
                tipsDetail3:'3. Refunds for gift card covered payment will be returned to your account and cannot be cashed out.',
                tipsDetail4:'4. This card is not restrictive and cannot be reported lost or cashed out.',
                tipsDetail5:'5、To buy gift cards, please contact customer service at 400-009-009.',
                toastContent1:'You are not logged in yet, please log in to redeem~',
                toastContent2:'Added the card'
            },
            record:{
                envTitle:'History cards',
                cardNum:'Card number:',
                yuan1:'￥',
                yuan2:'',
                finishedText:'No more~',
                loadingText:'loading...',
                nodataTxt:'No data yet',
            }
        }
    }
})